<template>
	<div class="out">
		<div class="consumption-count">
			<div class="">
				{{scope}} {{$t('main.points')}}
			</div>
			<div class="consumption-type">
				{{content | english(that)}}
			</div>
		</div>
		<div class="consumption-info">
			<div v-if="type == 2">
				<div class="consumption-info-item">
					{{$t('main.tfc')}} <span>{{goods.name}}</span>
				</div>
				<div class="consumption-info-item">
					{{$t('main.gfc')}} <span>{{goods.level}}</span>
				</div>
				<div class="consumption-info-item">
					{{$t('main.gno')}} <span>{{goods.serial_number}}</span>
				</div>
				<div class="consumption-info-item">
					{{$t('main.Deposit')}} <span>{{time}}</span>
				</div>
				<!-- <div class="consumption-info-item">
					{{$t('main.orderNo')}}<span>2022101022330001</span>
				</div> -->
			</div>
			<div v-else>
				<div class="consumption-info-item">
					{{$t('recharge.amount')}}：<span>￥{{price}}</span>
				</div>
				<div class="consumption-info-item">
					{{$t('recharge.time')}}<span>{{time}}</span>
				</div>
				<div class="consumption-info-item" v-show="order">
					{{$t('main.orderNo')}}<span>{{order}}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {

		data() {
			return {
				content:'',
				scope:'',
				type:1,
				price:'',
				time:'',
				order:'',
				goods:{
					name:'',
					level:'',
					serial_number:''
				},
				that:this
			}
		},
		mounted() {
			if (this.$route.query.id) {
				this.type = this.$route.query.type
				
				this.getConsumptionInfo(this.$route.query.id,this.$route.query.type)
			}
			// this.getConsumptionInfo(this.$route.query.id)
		},
		filters:{
			english(content,that){
				if(that.$i18n.locale == 'en'){
					switch(content){
						case"平台赠送":return 'Platform Gifts';break;
						case"删除珍宝回退":return 'Delete Treasure Rollback';break;
						case"充值成功":return 'Recharged successfully';break;
						case"珍宝存证":return 'Ordinary deposit certificate';break;
						case"稀有珍宝存证":return 'Rare deposit certificate';break;
						case"恢复珍宝扣除":return 'Restore Treasure Deduction';break;
						case"稀有珍宝存证(补差价)":return 'Rare Treasure Deposit Certificate (Pay Difference)';break;
						default:return content;break;
					}
				}
				return content
			}
		},
		methods: {
			getConsumptionInfo(id,type) {
				this.axios.post("/order/getTransactionDetail", {
						id:id,
						type:type
					})
					.then((res) => {
						
						if(type == 1){
							this.scope = '+'+parseInt(res.data.data.amount)
							this.content = res.data.data.content
							this.price = res.data.data.order? res.data.data.order.pay_amount : res.data.data.amount
							this.time = res.data.data.order? res.data.data.order.created_at : res.data.data.created_at
							this.order = res.data.data.order? res.data.data.order.order_number : ''
						}else{
							this.scope = parseInt(res.data.data.amount)
							this.content = res.data.data.content
							if(this.$i18n.locale == 'en'){
								this.goods.name = res.data.data.goods.name_intl ? res.data.data.goods.name_intl:res.data.data.goods.name
							}else{
								this.goods.name = res.data.data.goods.name
							}
							
							
							if(this.$i18n.locale == 'en'){
								this.goods.level = res.data.data.content.split('存证')[0]
								if(this.goods.level == '珍宝'){
									this.goods.level = "Treasure"
								}else{
									this.goods.level = "Rare Treasure"
								}
							}else{
								this.goods.level = res.data.data.content.split('存证')[0]
							}
							this.goods.serial_number = res.data.data.goods.serial_number
							this.time = res.data.data.created_at

						}
					})
					.catch((err) => {
						
					});
			}
		}
	}
</script>

<style scoped>
	.out {
		box-sizing: border-box;
		background: url(../assets/img/index_bg.png) no-repeat center;
		background-size: cover;
		background-attachment: fixed;
		height: 100vh;
	}

	.consumption-count {
		background: url(../assets/img/jifen_bg.png) no-repeat center;
		background-size: cover;
		color: #f2df8d;
		color: blanchedalmond;
		font-size: 0.25rem;
		text-align: center;
		height: 1.35rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.consumption-type {
		font-size: 0.13rem;
	}

	.consumption-info {
		padding: 0.315rem;
	}

	.consumption-info .consumption-info-item {
		font-size: 0.13rem;
		color: #6a6a6a;
		margin-bottom: 0.29rem;
	}

	.consumption-info .consumption-info-item span {
		color: #000000;

	}
</style>
